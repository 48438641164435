import { FC, useState } from 'react';
import styles from '../../../styles/intergration.module.scss';
import { ItemStatusBitrixType } from '@/models/intergrations/bitrix';
import { Radio } from 'antd';
import { useAppDispatch } from '@/hooks/redux';
import { api } from '@/Services';

const ItemStatus: FC<ItemStatusBitrixType> = ({ item }) => {
    const dispatch = useAppDispatch();
    const [status, set_status] = useState<string>(
        item?.name_logovo_status || ''
    );

    const changeStatus = (event) => {
        set_status(event.target.value);

        dispatch(
            api.account.changeStatusIntegrationCrm({
                crm_name: 'bitrix',
                crm_status_key: item?.key_crm_status,
                select_status_key: event.target.value,
                status_info: item,
            })
        );
    };

    return (
        <div
            className={styles.item}
            style={{
                background: item?.COLOR,
            }}
        >
            <h5>{item?.NAME}</h5>
            <div className={styles.logovoStatusList}>
                <Radio.Group
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    options={[
                        {
                            label: 'Нет статуса',
                            value: 'FREE',
                        },
                        {
                            label: 'Свободно',
                            value: 'FREE',
                        },
                        {
                            label: 'Продано',
                            value: 'SOLD',
                        },
                        {
                            label: 'Забронировано',
                            value: 'BOOKING',
                        },
                        {
                            label: 'Провалено',
                            value: 'LOSE',
                        },
                    ]}
                    value={status}
                    onChange={changeStatus}
                />
            </div>
        </div>
    );
};

export default ItemStatus;
