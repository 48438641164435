type TypeConfig = {
    public: string;
    app_version: string;
    graphqlURI: string;
    graphqlWSURI: string;
    TOKEN: string;
    graphqlURIDEV: string;
    graphqlWSURIDEV: string;
    api: string;
    domain: string;
    dev_api: string;
    tour_service: string;
};

const Config: TypeConfig = {
    public: 'https://files.logovo.kz/',
    app_version: '1.0.0',
    graphqlURI: 'https://api.logovo.kz/graphql',
    graphqlWSURI: 'wss://api.logovo.kz/graphql',
    TOKEN: 'SESSION_TOKEN',
    graphqlURIDEV: 'http://localhost:5724/graphql',
    graphqlWSURIDEV: 'ws://localhost:5724/graphql',
    api: 'https://api.logovo.kz',
    domain: 'https://logovo.kz',
    dev_api: 'http://localhost:5724',
    tour_service: 'https://viewer.logovo.kz/',
};

export default Config;
