import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        'flats',
        'companies',
        'chess',
        'chessData',
        'widgets',
        'realestate',
    ],
};

const pReducers = persistReducer(persistConfig, reducers);

export default pReducers;
